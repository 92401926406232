import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

// eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router";
interface SeoProps {
  data?: StoryblokMetaData;
  ogType: TemplateType;
}

interface SeoMetaData {
  site: {
    siteMetadata: {
      name: string;
      title: string;
      description: string;
      ogImage: string;
      twitterUsername: string;
    };
  };
}

export function Seo({ data, ogType }: SeoProps) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<SeoMetaData>(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            ogImage
            twitterUsername
          }
        }
      }
    `
  );
  const { name, title, description, ogImage, twitterUsername } =
    site.siteMetadata;
  const seo = {
    title: data?.title || title,
    description: data?.description || description,
    image: data?.og_image || ogImage,
    url: new URL(pathname, process.env.GATSBY_URL),
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.title === name ? `%s` : `%s | ${name}`}
      htmlAttributes={{
        lang: "en-US",
      }}
    >
      <link rel="canonical" href={seo.url.toString()} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:url" content={seo.url.toString()} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <script
        type="text/javascript"
        src="//js.hsforms.net/forms/shell.js"
      ></script>
    </Helmet>
  );
}
