import React from "react";
import SbEditable from "storyblok-react";

import Footer from "@/components/layouts/Footer";
import { Header } from "@/components/layouts/Header";
import { Seo } from "@/components/layouts/SEO";

import "slick-carousel/slick/slick.css";

interface Props {
  header?: HeaderTemplate;
  footer?: FooterTemplate;
  seo?: StoryblokMetaData;
  simpleSeo?: {
    title: string;
    description: string;
    imageUrl?: string;
  };
  siteName: string;
  pathname: string;
  hash?: string;
  children: React.ReactNode;
  alternates?: string[];
}
export default function Layout({
  header,
  footer,
  hash,
  children,
  seo,
  simpleSeo,
  pathname,
  alternates,
}: Props) {
  const seoData: StoryblokMetaData | undefined = simpleSeo
    ? {
        ...simpleSeo,
        // OpenGraph
        og_type: "website",
        og_description: simpleSeo.description,
        og_image: simpleSeo.imageUrl,
        // Twitter
        twitter_title: simpleSeo.title,
        twitter_description: simpleSeo.description,
        twitter_image: simpleSeo.imageUrl,
      }
    : seo;

  return (
    <React.Fragment>
      <Seo data={seoData} ogType={seo?.og_type || "website"} />

      {header && (
        <SbEditable content={header}>
          <Header
            {...header}
            hash={hash}
            path={pathname}
            alternates={alternates || []}
          />
        </SbEditable>
      )}
      <main className="mt-28">{children}</main>
      {footer && (
        <SbEditable content={footer}>
          <Footer {...footer} />
        </SbEditable>
      )}
    </React.Fragment>
  );
}
