import React from "react";
import { Link } from "gatsby";
import clsx from "clsx";

interface Props extends Pick<LinkType, "type" | "url" | "title" | "isButton"> {
  children?: React.ReactNode;
  activeclassname?: string;
  className?: string;
  isCurrent?: boolean;
  onClickHandler?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  ariaLabel?: string;
}

export default function LinkComponent({
  title,
  type,
  url,
  children,
  activeclassname,
  className,
  isCurrent,
  isButton,
  onClickHandler,
  ariaLabel,
}: Props) {
  if (type === "internal") {
    return (
      <Link
        to={url}
        activeClassName={!isButton ? activeclassname : undefined}
        className={clsx(className, {
          [activeclassname || "active"]: isCurrent && !isButton,
          "bg-secondary text-white rounded-lg px-14 py-4 xl:px-12  relative xl:whitespace-pre border-secondary border-2 hover:text-secondary hover:bg-transparent transition-all":
            isButton,
        })}
        onClick={onClickHandler}
      >
        {children}
        {title}
      </Link>
    );
  }
  return (
    <a
      href={url}
      rel="noreferrer"
      target={url && url.substring(0, 6) === "mailto" ? "_self" : "_blank"}
      className={`${className || ""}`}
      onClick={onClickHandler}
      aria-label={
        url && url.substring(0, 6) === "mailto"
          ? undefined
          : `${ariaLabel || title || ""}, opens a new tab`
      }
    >
      {children}
      {title}
    </a>
  );
}
