export default function sendToGTM(data: {
  event: string;
  filterName?: string;
  searchQuery?: string;
  articleCategories?: string[];
  articleURL?: string;
}) {
  if (process.env.NODE_ENV === "development") return;
  if (window?.dataLayer) {
    // eslint-disable-next-line no-console
    console.log(`SENDED GTM EVENT WITH NAME: ${data.event}`);
    window.dataLayer.push(data);
  } else {
    // eslint-disable-next-line no-console
    console.warn(`dataLayer does not exist, has script be initialized`);
  }
}
