import React from "react";

import { ReactComponent as Email } from "@/assets/svg/email-ico.svg";
import { ReactComponent as FacebookIco } from "@/assets/svg/facebook-ico.svg";
import { ReactComponent as Instagram } from "@/assets/svg/instagram-ico.svg";
import { ReactComponent as LinkedinIco } from "@/assets/svg/linkedin-ico.svg";
import { ReactComponent as MediumIco } from "@/assets/svg/medium-ico.svg";
import { ReactComponent as Toutiao } from "@/assets/svg/toutiao-ico.svg";
import { ReactComponent as TwitterIco } from "@/assets/svg/twitter-ico.svg";
import { ReactComponent as Website } from "@/assets/svg/website-ico.svg";
import { ReactComponent as WeChat } from "@/assets/svg/wechat-ico.svg";
import { ReactComponent as Weibo } from "@/assets/svg/weibo-ico.svg";
import { ReactComponent as Youtube } from "@/assets/svg/youtube-ico.svg";

const types: { [key: string]: React.ReactNode } = {
  facebook: <FacebookIco />,
  twitter: <TwitterIco />,
  linkedin: <LinkedinIco />,
  medium: <MediumIco />,
  email: <Email />,
  wechat: <WeChat />,
  weibo: <Weibo />,
  toutiao: <Toutiao />,
  website: <Website />,
  instagram: <Instagram />,
  youtube: <Youtube />,
};

interface Props extends SocialLinkType {
  className?: string;
}

export default function SocialLink({ type, url, className }: Props) {
  // returning EMPTY looks unnecessary, just return null if no icon matched
  const parsedUrl = type === "email" ? `mailto:${url}` : url;
  return types[type] ? (
    <a
      className={`block text-black ${className && className}`}
      href={parsedUrl}
      aria-label={`${
        type.charAt(0).toUpperCase() + type.slice(1)
      }, opens a new tab`}
      target={url && url.substring(0, 6) === "mailto" ? "_self" : "_blank"}
      rel="noreferrer"
    >
      {types[type]}
    </a>
  ) : null;
}
