/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";

import LinkComponent from "@/components/elements/Link";

import * as styles from "./styles.module.css";

export function Dropdown({
  title,
  links = [],
  inline = false,
  className,
}: DropdownType) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleOpen);
    }

    return () => {
      document.removeEventListener("click", handleOpen);
    };
  }, [open]);

  function handleOpen(
    e:
      | React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>
      | MouseEvent
  ) {
    if (
      e.target instanceof HTMLDivElement ||
      e.target instanceof HTMLSpanElement
    ) {
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return setOpen(!open);
      } else {
        return setOpen(false);
      }
    }
  }

  return (
    <div
      className={clsx("relative z-50", styles.dropdown, className)}
      onClick={(e) => handleOpen(e)}
      ref={menuRef}
    >
      <span
        className={clsx(
          "relative cursor-pointer",
          open ? styles.dropdown__arrow__up : styles.dropdown__arrow__down
        )}
      >
        <span>{title}</span>
      </span>
      <div
        className={clsx(
          "h-0 overflow-hidden transition-all duration-300 ease-in-out",
          !inline && "lg:absolute lg:-ml-8 lg:opacity-0",
          open && "static h-auto",
          styles.listWrapper,
          inline && styles.inline
        )}
      >
        <ul
          className={clsx(
            "w-full block text-center",
            !inline && "lg:p-6 lg:pt-1 lg:mt-5 lg:bg-white"
          )}
        >
          {links.map((item) => (
            <li
              key={item._uid}
              className={clsx(
                "mt-4 text-md lg:first:mt-0 lg:relative lg:hover:text-secondary"
              )}
            >
              <LinkComponent {...item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
