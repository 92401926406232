import React, { useContext } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import { useLanguageContext } from "@/context";
import NewsImage from "@/assets/img/news.png";
import { ReactComponent as WhiteLogo } from "@/assets/svg/logo-vertex-white.svg";
import { ImageComponent } from "@/components/elements/Image";
import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import formatDate from "@/base/helpers/formatDate";
import sendToGTM from "@/base/utils/sendToGTM";
import * as styles from "./styles.module.css";

interface Props {
  postData:
    | (PostTemplate & Pick<PostType, "uuid" | "slug" | "full_slug">)
    | NewsroomPostType;
  className?: string;
  isLinkingFromDifferentThanNews?: boolean;
}

const checkAuthor = (authorString: string, authorMember: MemberType) => {
  if ((authorString && authorMember) || authorMember) {
    return false;
  }
  return true;
};

const prepareLink = (
  linkUrl: string,
  linkType: "external" | "internal",
  isLinkingFromDifferentThanNews: boolean
) => {
  if (linkType === "internal" && isLinkingFromDifferentThanNews) {
    return `/news/${linkUrl}`;
  }
  return linkUrl;
};

export const NewsEntry = ({
  postData,
  className,
  isLinkingFromDifferentThanNews = false,
}: Props) => {
  const {
    state: { DEFAULT_LANG },
  } = useContext(useLanguageContext);
  const url =
    postData.full_slug.indexOf(DEFAULT_LANG) >= 0
      ? postData.full_slug.replace(`${DEFAULT_LANG}/`, "")
      : postData.full_slug;
  const linkUrl = postData.external_post
    ? postData.external_link
    : url.replace("news/", "").replace("perspectives/", "");
  const linkType = postData.external_post ? "external" : "internal";

  const {
    author,
    author_teamMember,
    post_author,
    post_excerpt,
    post_title,
    post_hero,
    post_date,
    post_category,
  } = postData;
  const isAuthor =
    checkAuthor(author, author_teamMember) &&
    !!post_author &&
    typeof post_author === "object";
  const bgColor = isAuthor
    ? post_author.content?.project_color?.color
    : "#7dcaef";
  const logo = isAuthor ? post_author.content?.project_logoWhite : null;
  const projectName = post_author?.name;

  const categories = post_category.map((category) => category.name);

  function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    if (linkType === "external") {
      if (linkUrl) {
        window.open(linkUrl, "_blank");
        sendToGTM({
          event: "Outbound Article Clicked",
          articleCategories: categories,
          articleURL: linkUrl,
        });
      }
    } else {
      navigate(`/news/${linkUrl}`);
      sendToGTM({
        event: "News Viewed",
        articleCategories: categories,
        articleURL: `${process.env.GATSBY_URL}news/${linkUrl}`,
      });
    }
  }

  return (
    <article
      className={clsx(
        "bg-white rounded-lg shadow-lg mb-10 2xl:mb-16",
        styles.wrapper,
        className
      )}
    >
      <div
        style={{ backgroundColor: bgColor }}
        className="flex justify-end items-center pr-3 rounded-t-lg h-9"
      >
        {isAuthor && logo?.filename ? (
          <img
            src={logo.filename}
            alt={projectName ?? ""}
            width={70}
            className="block object-contain"
          />
        ) : (
          <WhiteLogo className="w-5 h-5" />
        )}
      </div>
      {post_hero && post_hero.image ? (
        <ImageComponent fluid data={post_hero} />
      ) : (
        <img src={NewsImage} alt="" className="w-full h-auto" />
      )}
      <div className="p-10 pb-14 2xl:p-11 2xl:pb-16">
        <span className="text-base mb-6 block font-fieldwork-hum">
          {formatDate(post_date)}
        </span>
        <div className={styles.excerpt}>
          <LinkComponent
            url={prepareLink(linkUrl, linkType, isLinkingFromDifferentThanNews)}
            type={linkType}
            onClickHandler={handleLinkClick}
          >
            <h2>{post_title}</h2>
          </LinkComponent>
          {post_excerpt && (
            <MarkdownComponent>{post_excerpt}</MarkdownComponent>
          )}
        </div>
        <div
          className={clsx("flex items-center text-secondary", styles.read_more)}
        >
          <LinkComponent
            url={prepareLink(linkUrl, linkType, isLinkingFromDifferentThanNews)}
            type={linkType}
            className="block leading-none font-fieldwork-hum font-semibold text-xs capitalize lg:text-lg"
            onClickHandler={handleLinkClick}
          >
            Read more
            <span className={clsx("align-middle ml-4", styles.arrow)}></span>
          </LinkComponent>
        </div>
      </div>
    </article>
  );
};
