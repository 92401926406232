/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from "react";
import clsx from "clsx";

import { useLanguageContext, useMatchMediaContext } from "@/context";
import { Dropdown } from "@/components/elements/Dropdown";
import LinkComponent from "@/components/elements/Link";

import * as styles from "./styles.module.css";

interface Props {
  links: (LinkType | DropdownType)[];
  open: boolean;
  onClick: () => void;
  hash?: string;
  alternatePath?: string;
  path: string;
}

export default function MainNav({ links = [], open, onClick, hash }: Props) {
  const {
    state: { language, DEFAULT_LANG },
  } = useContext(useLanguageContext);
  const { isDesktop } = useMatchMediaContext();
  const langPrefix = language === DEFAULT_LANG ? "" : `/${language}`;

  return (
    <nav
      className={clsx(
        "w-screen fixed top-0 left-0 z-40 pb-6 bg-white bg-opacity-90 transition-all duration-300 ease-in-out xs:pt-12 xl:pb-0 xl:static xl:w-auto xl:h-auto xl:pt-0 xl:ml-auto xl:mr-10 xl:opacity-100 xl:visible xl:z-0 xl:transition-none overflow-auto xl:overflow-visible",
        open
          ? "h-[calc(100%-5rem)] mt-20 xs:h-screen xs:mt-0 visible opacity-100"
          : "h-0 invisible opacity-0"
      )}
    >
      <ul className="flex flex-col items-center justify-center overflow-y-auto lg:overflow-visible xl:justify-start xl:flex-row">
        {links.map((link) =>
          link.component === "element_dropdown" ? (
            <li
              key={link._uid}
              className="py-5 mb-5 xl:mb-0 md:text-4xl xl:text-md xl:mr-10 2xl:mr-14"
            >
              <div className="relative text-center">
                <Dropdown
                  {...link}
                  className={styles.dropdown}
                  inline={!isDesktop}
                />
              </div>
            </li>
          ) : (
            <li
              key={link._uid}
              onClick={onClick}
              className="xl:last:mr-0 xl:mr-10 2xl:mr-14"
            >
              <LinkComponent
                isButton={link.isButton}
                url={langPrefix + link.url}
                type={link.type}
                isCurrent={!!hash && link.url.indexOf(hash) >= 0}
                className="block"
                activeclassname="block relative after:w-4 xl:text-secondary xl:after:absolute xl:after:block xl:after:left-1/2 xl:after:bottom-4 xl:after:h-0.5 xl:after:bg-secondary xl:after:-ml-2 xl:text-md"
              >
                {link.isButton ? (
                  link.title
                ) : (
                  <button className="py-3 font-light tracking-wider relative md:text-4xl xl:text-md xl:py-5 xl:whitespace-pre xl:hover:text-secondary xl:hover:after:w-4 xl:hover:after:absolute xl:hover:after:block xl:hover:after:left-1/2 xl:hover:after:bottom-4 xl:hover:after:h-0.5 xl:hover:after:bg-secondary xl:hover:after:-ml-2">
                    {link.title}
                  </button>
                )}
              </LinkComponent>
            </li>
          )
        )}
      </ul>
    </nav>
  );
}
