import React from "react";
// eslint-disable-next-line import/named
import ReactMarkdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";

interface Props {
  children: string;
  className?: string;
  overrides?: Components;
}

export default function MarkdownComponent(props: Props) {
  return (
    <div className={props.className}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          a: ({ href, children }) => {
            const label =
              typeof children === "string"
                ? children
                : children?.length
                ? typeof children[0] === "string"
                  ? children[0]
                  : (children[0] as JSX.Element)?.props?.children
                : "" || "";
            return (
              <a
                href={href}
                rel="noreferrer"
                target={
                  href && href.substring(0, 6) === "mailto" ? "_self" : "_blank"
                }
                aria-label={
                  href && href.substring(0, 6) === "mailto"
                    ? undefined
                    : `${label}, opens a new tab`
                }
              >
                {children}
              </a>
            );
          },
          p: ({ children }) => {
            let className;
            const elements = children?.map((child) => {
              if (typeof child === "string" && child.trim() === "\\") {
                className = "empty";
                return "\u00A0";
              }
              // fix the issue with incorrect markdown markup for strong
              if (typeof child === "string") {
                const regex = /(\*\*)(.*)(\*\*)/;
                const value = child.replace(" **", "** ");
                const matches = value.match(regex);
                return matches ? (
                  <>
                    <strong>{matches[2]}</strong>&nbsp;
                  </>
                ) : (
                  value
                );
              }
              return child;
            });

            return <p className={className}>{elements}</p>;
          },
          ul: (props) => {
            return (
              <ul className="ml-6 -mt-2 mb-4 list-disc">{props.children}</ul>
            );
          },

          ol: (props) => {
            return (
              <ul className="ml-6 -mt-2 mb-4 list-decimal">{props.children}</ul>
            );
          },

          li: (props) => {
            return <li className="mb-2">{props.children}</li>;
          },

          iframe: (props) => {
            const padding =
              props.height && props.width
                ? (parseFloat(`${props.height}` as string) /
                    parseFloat(`${props.width}`)) *
                  100
                : 56.25;
            return (
              <div
                className={`w-full h-0 relative mb-6`}
                style={{ paddingBottom: `${padding}%` }}
              >
                <iframe
                  {...props}
                  title={props.title}
                  className="absolute left-0 top-0 w-full h-full"
                />
              </div>
            );
          },

          ...props.overrides,
        }}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
}
