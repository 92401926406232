import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { RemoveScroll } from "react-remove-scroll";

import { useLanguageContext, useMatchMediaContext } from "@/context";
import LogoSvg from "@/assets/svg/logo_sea.svg";
import LinkComponent from "@/components/elements/Link";
import MainNav from "./MainNav";

interface Props extends HeaderTemplate {
  path: string;
  hash?: string;
  alternates: string[];
}

export function Header({ main_nav, hash, path, alternates }: Props) {
  const [menuOpen, toggleMenu] = useState(false);
  const alternatePath = alternates[0] || ``;
  const {
    state: { language },
  } = useContext(useLanguageContext);
  const { isDesktop } = useMatchMediaContext();

  function handleHamburgerClick() {
    if (!isDesktop) {
      toggleMenu(!menuOpen);
    }
  }
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    toggleMenu(false);
  }, [isDesktop]);

  return (
    <header className="z-50 w-full fixed top-0 bg-white" ref={headerRef}>
      <RemoveScroll
        enabled={menuOpen}
        className="flex justify-between items-center mx-auto max-w-x-big px-4 h-28"
      >
        <div className="z-50 max-h-24">
          <LinkComponent url={language === "zh" ? "/zh/" : "/"} type="internal">
            <img
              src={LogoSvg}
              alt="Vertex SEA and India homepage"
              className="w-40 block lg:w-56 2xl:w-48"
            />
          </LinkComponent>
        </div>
        <div className="z-50 xl:hidden">
          <button
            className="block cursor-pointer relative w-6 h-11"
            aria-expanded={menuOpen ? true : false}
            aria-labelledby="close"
            onClick={handleHamburgerClick}
          >
            <span hidden id="close">
              Close
            </span>
            <span
              className={clsx(
                "block absolute h-px w-6 bg-primary border-2 border-primary transition-all duration-300 ease-in-out",
                {
                  "rotate-45 top-15 opacity-40": menuOpen,
                  "rotate-0 top-4 opacity-100": !menuOpen,
                }
              )}
              aria-hidden="true"
            ></span>
            <span
              className={clsx(
                "block absolute h-px w-6 bg-primary border-2 border-primary transition-all duration-300 ease-in-out",
                {
                  "-rotate-45 top-15 opacity-40": menuOpen,
                  "rotate-0 top-6 opacity-100": !menuOpen,
                }
              )}
              aria-hidden="true"
            ></span>
          </button>
        </div>

        <MainNav
          links={main_nav}
          open={menuOpen}
          onClick={handleHamburgerClick}
          hash={hash}
          path={path}
          alternatePath={alternatePath}
        />
      </RemoveScroll>
    </header>
  );
}
